import React, {useCallback, useState} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import ImageViewer from "react-simple-image-viewer";
import CalculatorAccordion from "./CalculatorAccordion";
import "../../assets/sass/external.scss"
import Header from "./Header";
import Blurb from "./Blurb";
import CookieConsentBanner from "../CookieConsentBanner";
import FirstTimeBuyers from "./FirstTimeBuyers";
import {
    FaBath,
    FaBorderAll,
    FaDoorClosed,
    FaDoorOpen,
    FaFire,
    FaFireAlt,
    FaHome,
    FaLemon,
    FaLock,
    FaPaintRoller,
    FaPowerOff,
    FaRecycle,
    FaSun,
    FaThumbsUp,
    FaTree
} from "react-icons/fa";
import RegisterInterest from "../RegisterInterest";

function Index(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const images = [
        "/assets/images/hallmark/castlecourt-type-a-plan.jpg",
        "/assets/images/hallmark/castlecourt-type-b-plan.jpg",
        "/assets/images/hallmark/castlecourt-type-c-plan.jpg",
        "/assets/images/hallmark/castlecourt-type-d-plan.jpg",
        "/assets/images/hallmark/castlecourt-type-e-plan.jpg",
        "/assets/images/hallmark/castlecourt-type-f-plan.jpg",
    ];
    return (
        <>
            <div className="min-vh-100 w-100 iframe-container">
                <Header/>

                <Container className="px-xs-0-only position-relative">
                    <Row className="py-4 gx-0 gx-sm-5">
                        <Col className="blurb order-2 order-lg-1" xs={12} lg={7}>
                            <Card className="mt-3 bg-body-color">
                                <Card.Body className="p-md-5">
                                    <Blurb/>
                                </Card.Body>
                                <div className="bg-stonework text-white">
                                    <div
                                        className="d-flex flex-column flex-sm-row justify-content-around align-content-stretch  position-relative text-start text-sm-center">
                                        <div className="p-2 py-3 small" style={{backgroundColor: 'rgba(0,59,74,0.75)'}}>
                                            Natural stone on certain elevations and boundaries
                                        </div>
                                        <div className="p-2 py-3 small "
                                             style={{backgroundColor: 'rgba(29,99,116,0.75)'}}>
                                            Architecturally designed unique homes
                                        </div>
                                        <div className="p-2 py-3 small "
                                             style={{backgroundColor: 'rgba(87,144,158,0.75)'}}>
                                            Elevated ceilings make for rooms that feel open
                                        </div>
                                        <div className="p-2 py-3 small"
                                             style={{backgroundColor: 'rgba(127,168,174,0.75)'}}>
                                            Professionally designed landscaping throughout
                                        </div>
                                    </div>

                                </div>
                                <Card.Body className="p-md-5">
                                    <h3>For First Time Buyers</h3>
                                    <FirstTimeBuyers/>
                                </Card.Body>

                            </Card>
                            <Row className="my-3 my-md-5 mx-5 px-0 px-md-5 align-items-center">
                                <Col>
                                    <img className=" w-100" src="/assets/images/hallmark/ber-a.png" alt="BER A"
                                         loading="lazy"/>

                                </Col>
                                <Col>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.09 18.71">
                                        <title>ber-a2</title>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="type">
                                                <g>
                                                    <polygon points="0 0 0 18.71 49.26 18.71 53.09 9.06 49.19 0 0 0"
                                                             fill="#fff"></polygon>
                                                    <polygon
                                                        points="47.77 2.14 29.79 2.14 32.88 9.12 29.79 16.58 47.77 16.58 50.76 9.06 47.77 2.14"
                                                        fill="#00a650"></polygon>
                                                    <path
                                                        d="M36.66,10.23h2l-1-3.16h0Zm-1,3H33.9l2.85-8h1.93l2.81,8H39.68l-.51-1.65h-3Z"
                                                        fill="#fff"></path>
                                                    <path
                                                        d="M42.1,8.15c0-2.57,1.87-2.79,2.58-2.79a2.46,2.46,0,0,1,2.71,2.49,3.05,3.05,0,0,1-1.64,2.57c-1,.73-1.6,1.08-1.78,1.45H47.4v1.38H42a3.52,3.52,0,0,1,1.83-3.13c1.43-1,2-1.44,2-2.33a1.07,1.07,0,0,0-1.1-1.12c-1.09,0-1.12.9-1.13,1.48Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                    <path d="M27.47,2.14l3.08,7-3.08,7.46m0,0H2.14V2.14H27.47"></path>
                                                    <path
                                                        d="M6.92,11.94H9c.81,0,1.27-.35,1.27-1.16s-.6-1-1.2-1H6.92Zm0-3.47H8.87c.66,0,1.18-.26,1.18-.89s-.46-.86-1.22-.86H6.92ZM5.32,5.35H9.2c2,0,2.46,1.22,2.46,2a1.59,1.59,0,0,1-1,1.63A1.86,1.86,0,0,1,11.91,11a2.28,2.28,0,0,1-.69,1.64c-.45.44-.89.72-2.52.72H5.32Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                    <polygon
                                                        points="18.81 6.76 14.57 6.76 14.57 8.44 18.47 8.44 18.47 9.85 14.57 9.85 14.57 11.91 19 11.91 19 13.32 12.94 13.32 12.94 5.35 18.81 5.35 18.81 6.76"
                                                        fill="#fff"></polygon>
                                                    <path
                                                        d="M23.54,8.86c.87,0,1.29-.3,1.29-1.09,0-.43-.18-1-1.16-1H21.59V8.86ZM20,5.35h4A2.24,2.24,0,0,1,26.5,7.6a1.9,1.9,0,0,1-1.23,1.9c.66.29,1,.53,1,2,0,1.17,0,1.46.36,1.59v.21H24.79a5.35,5.35,0,0,1-.18-1.61c0-1.09-.07-1.51-1.3-1.51H21.59v3.12H20Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </Col>
                            </Row>
                            <img className="w-100 mt-auto" src="/assets/images/hallmark/castlecourt-map.jpg"
                                 alt="Map to us" loading="lazy"/>

                        </Col>
                        <Col  className="order-1 order-lg-2 " xs={12} lg={5}>
                            <div className=" shadow mt-lg--100 mb-5">
                                <RegisterInterest/>

                            </div>
                                <CalculatorAccordion/>

                        </Col>
                    </Row>

                    <h3 className="mt-5 mb-3 p-3 p-md-5 bg-info text-white">Castle Court Homes come with extraordinary
                        benefits</h3>
                    <Row className="my-5 gx-0 px-3 gx-sm-5 px-sm-0 ">
                        <Col xs={12} lg={6} xl={4}>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">10 Year Homebond Guarantee</h5>
                                    <FaThumbsUp size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">Each home is covered by a
                                    10-year Structural Defects
                                    HomeBond insurance warranty
                                    and a 5-year Mechanical and
                                    Electrical Inherent Defects
                                    warranty offering unrivalled
                                    insurance protection for your
                                    home.</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Fires</h5>
                                    <FaFire size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">Each home is covered by a
                                    Full control over the fire’s
                                    host of features, including
                                    temperature control as well
                                    as flame brightness levels and
                                    colour, you can also access the
                                    programmable heating options
                                    and innovative energy saving
                                    features for complete control.</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Gardens & Driveway</h5>
                                    <FaTree size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">Each home is covered by a
                                    Professionally designed
                                    landscaping throughout the
                                    development. All gardens are
                                    generous in size and come with
                                    seeded lawns surrounded by a
                                    secure 1.8m timber fence with
                                    concrete posts providing privacy
                                    on each side of the garden.
                                    Cobblelock paving and concrete
                                    footpaths provided as standard.</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Internal Doors</h5>
                                    <FaDoorClosed size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">High-quality oak finish doors
                                    are fitted with satin chromefinished
                                    handles, hinges and
                                    locks.</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Interior Finishes</h5>
                                    <FaPaintRoller size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">Increased ceiling heights create
                                    rooms that feel open and
                                    inviting. Superior-quality internal
                                    joinery is present throughout
                                    and includes oak handrails to
                                    stairs. A sealed attic hatch with
                                    pull-down ladder allows easy
                                    access to additional storage
                                    area</p>
                            </div>
                        </Col>
                        <Col xs={12} lg={6} xl={4}>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Kitchen & Utility</h5>
                                    <FaLemon size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">Each home is covered by a
                                    Imaginatively designed, superior
                                    quality kitchens which are fully
                                    fitted by our award winning
                                    suppliers. The utility rooms
                                    are also designed to maximise
                                    on storage with units that
                                    complement the kitchen. (PC
                                    Sum Allowance).</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Security & Safety</h5>
                                    <FaLock size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">Mains-powered smoke, carbon
                                    monoxide and heat detectors
                                    are fitted throughout the house.
                                    All windows and doors come
                                    with multi-point locking systems.</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Windows</h5>
                                    <FaBorderAll size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">High-quality uPVC windows
                                    with soft-coat, low-emissivity
                                    glass and multi chamber, steel
                                    reinforced frames combine to
                                    significantly reduce heat loss</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">External Doors</h5>
                                    <FaDoorOpen size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">Market leading (Palladio)
                                    heavy-duty engineered multipoint
                                    locking door giving the
                                    aesthetic appeal of a traditional
                                    timber door while providing
                                    exceptional strength, security
                                    and thermal performance
                                    characteristics</p>
                            </div>
                            <div className="benefit">
                                <div
                                    className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                    <h5 className="mb-0">Heat-recovery Ventilation</h5>
                                    <FaRecycle size={30} className="flex-shrink-0 text-info"/>
                                </div>
                                <p className="small">This system provides a healthier
                                    living environment while
                                    significantly reducing the
                                    heating demand. The outgoing
                                    air passes through a heat
                                    exchanger and preheats the
                                    incoming air, recovering 90% of
                                    the heat that would otherwise
                                    be lost. The air in the house is
                                    changed more frequently than
                                    naturally ventilated houses
                                    and all incoming air is filtered,
                                    reducing dust and other
                                    pollutants creating a fresher,
                                    healthier place to live.</p>
                            </div>
                        </Col>
                        <Col xs={12} lg={12} xl={4}>
                            <Row className="gx-0 gx-sm-5">
                                <Col lg={6} xl={12}>
                                    <div className="benefit">
                                        <div
                                            className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                            <h5 className="mb-0">External Walls</h5>
                                            <div className="text-info">
                                                <FaSun size={30} className="flex-shrink-0 text-info"/>
                                            </div>

                                        </div>
                                        <p className="small">Natural stone on certain
                                            elevations, painted smooth
                                            plaster rendered walls provide
                                            a high-quality, contemporary
                                            finish. Low maintenance uPVC
                                            fascia, soffit and rainwater pipes
                                            complement the natural slate
                                            finish.</p>
                                    </div>
                                    <div className="benefit">
                                        <div
                                            className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                            <h5 className="mb-0">Heating</h5>
                                            <FaFireAlt size={30} className="flex-shrink-0 text-info"/>
                                        </div>
                                        <p className="small">An ‘A’ Rated split bi-zone Air
                                            Source Heat Pump allows
                                            precise control over the three
                                            heating zones – living, sleeping
                                            and water. In addition to
                                            the separate heating zones,
                                            radiators are fitted with
                                            thermostatically-controlled
                                            valves providing additional
                                            room-by-room control.</p>
                                    </div>
                                    <div className="benefit">
                                        <div
                                            className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                            <h5 className="mb-0">Building Fabric</h5>
                                            <FaHome size={30} className="flex-shrink-0 text-info"/>
                                        </div>
                                        <p className="small">Passive principles have been
                                            applied to the building fabric
                                            with a high-level of insulation,
                                            improved air-tightness, and
                                            reduced thermal bridges which
                                            result in a reduced energy
                                            demand and increased levels of
                                            comfort.</p>
                                    </div>
                                </Col>
                                <Col lg={6} xl={12}>
                                    <div className="benefit">
                                        <div
                                            className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                            <h5 className="mb-0">Bathrooms & En-suites</h5>
                                            <FaBath size={30} className="flex-shrink-0 text-info"/>
                                        </div>
                                        <p className="small">Each home is covered by a
                                            All bathrooms and en-suites
                                            come fully- fitted with a
                                            sophisticated range of designer
                                            sanitary ware. A high-pressure
                                            water supply is pumped to all
                                            showers. (Shower door/screen
                                            not included).</p>
                                    </div>
                                    <div className="benefit">
                                        <div
                                            className="d-flex align-items-end justify-content-between border-bottom border-info pb-1">
                                            <h5 className="mb-0">Electrical & Media</h5>
                                            <FaPowerOff size={30} className="flex-shrink-0 text-info"/>
                                        </div>
                                        <p className="small">High-speed data points and
                                            a generous allowance of high
                                            quality switches, sockets and
                                            telephone points throughout.
                                            The development provides for
                                            super-fast broadband and
                                            cable TV.</p>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>

                <div className="bg-body-color py-lg-5">
                        <Container className="px-xs-0-only" style={{overflowX: 'hidden'}}>
                            <h3 className="text-center mb-5">Floor Plans</h3>
                            <Row>
                                <Col xs={12} md={4} className="mb-3 mb-md-0 pb-3">
                                    <Card className="h-100 hoverable" onClick={() => openImageViewer(0)}>
                                        <Card.Img variant="top"
                                                  src="/assets/images/hallmark/castlecourt-type-a-image.jpg"
                                                  loading="lazy"/>
                                        <Card.Body className="d-flex flex-column" style={{backgroundColor: '#e3e3e3'}}>
                                            <Card.Title className="bold">TYPE A - 4 BED SEMI-DETACHED</Card.Title>
                                            <Card.Text>
                                                126sq.m (1356.26sq.ft)
                                            </Card.Text>
                                            <img className="w-100"
                                                 src="/assets/images/hallmark/castlecourt-type-a-plan.jpg"
                                                 alt="Castle Court - A Plan" loading="lazy"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={4} className="mb-3 mb-md-0 pb-3">
                                    <Card className="h-100 hoverable" onClick={() => openImageViewer(1)}>
                                        <Card.Img variant="top"
                                                  src="/assets/images/hallmark/castlecourt-type-b-image.jpg"
                                                  loading="lazy"/>
                                        <Card.Body className="d-flex flex-column" style={{backgroundColor: '#e3e3e3'}}>
                                            <Card.Title className="bold">TYPE B - 3 BED TERRACE</Card.Title>
                                            <Card.Text>
                                                124sq.m (1334.74sq.ft)
                                            </Card.Text>
                                            <img className="w-100"
                                                 src="/assets/images/hallmark/castlecourt-type-b-plan.jpg"
                                                 alt="Castle Court - A Plan" loading="lazy"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={4} className="mb-3 mb-md-0 pb-3">

                                    <Card className="h-100 hoverable" onClick={() => openImageViewer(2)}>
                                        <Card.Img variant="top"
                                                  src="/assets/images/hallmark/castlecourt-type-c-image.jpg"
                                                  loading="lazy"/>
                                        <Card.Body className="d-flex flex-column" style={{backgroundColor: '#e3e3e3'}}>
                                            <Card.Title className="bold">TYPE C - 3 BED SEMI-DETACHED</Card.Title>
                                            <Card.Text>
                                                94sq.m (1011.82sq.ft)
                                            </Card.Text>
                                            <img className="w-100"
                                                 src="/assets/images/hallmark/castlecourt-type-c-plan.jpg"
                                                 alt="Castle Court - A Plan" loading="lazy"/>
                                        </Card.Body>
                                    </Card>

                                </Col>
                                <Col xs={12} md={4} className="mb-3 mb-md-0 pb-3">
                                    <Card className="h-100 hoverable" onClick={() => openImageViewer(3)}>
                                        <Card.Img variant="top"
                                                  src="/assets/images/hallmark/castlecourt-type-d-image.jpg"
                                                  loading="lazy"/>
                                        <Card.Body className="d-flex flex-column" style={{backgroundColor: '#e3e3e3'}}>
                                            <Card.Title className="bold">TYPE D - 4 BED DETACHED</Card.Title>
                                            <Card.Text>
                                                193sq.m (2077.45sq.ft)
                                            </Card.Text>
                                            <img className="w-100"
                                                 src="/assets/images/hallmark/castlecourt-type-d-plan.jpg"
                                                 alt="Castle Court - A Plan" loading="lazy"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={4} className="mb-3 mb-md-0 pb-3">
                                    <Card className="h-100 hoverable" onClick={() => openImageViewer(4)}>
                                        <Card.Img variant="top"
                                                  src="/assets/images/hallmark/castlecourt-type-e-image.jpg"
                                                  loading="lazy"/>
                                        <Card.Body className="d-flex flex-column" style={{backgroundColor: '#e3e3e3'}}>
                                            <Card.Title className="bold">TYPE E - 4 BED DETACHED</Card.Title>
                                            <Card.Text>
                                                193sq.m (2077.45sq.ft)
                                            </Card.Text>
                                            <img className="w-100"
                                                 src="/assets/images/hallmark/castlecourt-type-e-plan.jpg"
                                                 alt="Castle Court - A Plan" loading="lazy"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={4} className="mb-3 mb-md-0 pb-3">
                                    <Card className="h-100 hoverable" onClick={() => openImageViewer(5)}>
                                        <Card.Img variant="top"
                                                  src="/assets/images/hallmark/castlecourt-type-f-image.jpg"
                                                  loading="lazy"/>
                                        <Card.Body className="d-flex flex-column" style={{backgroundColor: '#e3e3e3'}}>
                                            <Card.Title className="bold">TYPE F - 4 BED DETACHED</Card.Title>
                                            <Card.Text>
                                                198sq.m (2131.27sq.ft)
                                            </Card.Text>
                                            <img className="w-100"
                                                 src="/assets/images/hallmark/castlecourt-type-f-plan.jpg"
                                                 alt="Castle Court - A Plan" loading="lazy"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                <div className="bg-info text-info py-3 py-lg-4">
                    <Container className="px-xs-0-only">
                        <Row className="gx-0 gx-sm-3">
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center h-100 py-4 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                        <h3> SELLING AGENTS</h3>
                                        <div className="mt-auto">
                                            <img src="/assets/images/hallmark/castlecourt-logo-savills.jpg" loading="lazy" alt="Savills Logo"/>
                                        </div>

                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                <div className="text-center py-4 h-100 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                <h3> DEVELOPER</h3>
                                    <div className="mt-auto">
                                    <img src="/assets/images/hallmark/castlecourt-logo-vesta.jpg" loading="lazy" alt="Vesta Logo"/>
                                    </div>
                                </div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                <div className="text-center py-4 h-100 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                <h3 > PROJECT MANAGER</h3>
                                    <div className="mt-auto">
                                    <img src="/assets/images/hallmark/castlecourt-logo-hallmark.jpg" loading="lazy" alt="Hallmark Logo"/>
                                    </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                <div className="text-center py-4 h-100 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                    <h3 > MORTGAGE PARTNER</h3>
                                    <div className="mt-auto mb-2">
                                    <img src="/assets/images/hallmark/sussd-logo.svg" loading="lazy" alt="Sussd Logo"/>
                                    </div>
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>


                {isViewerOpen && (
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={true}
                        backgroundStyle={{
                            backgroundColor: "rgba(0,0,0,0.7)"
                        }}
                        closeOnClickOutside={true}
                    />
                )}
            </div>
            <CookieConsentBanner/>
        </>
    );
}

export default Index;