import React, {useEffect} from "react"
import Wrapper from "../components/calculator/Wrapper";
import uuid from "react-uuid";
function Index(props) {
    useEffect(()=>{
        let session = localStorage.getItem('sussd-session')
        if (!session) {
            localStorage.setItem('sussd-session', uuid())
        }
    },[])
    return <Wrapper/>
}
export default Index

export function Head(){
    return <>
        <title>{`Castle Court, Whitechurch`}</title>
        <meta charSet="UTF-8"/>
        <meta content="Register your interest in a new home in Castle Court, Whitechurch" name="description"/>
        <meta content="Castle Court, Whitechurch" property="og:title"/>
        <meta content="Register your interest in a new home in Castle Court, Whitechurch" property="og:description"/>
        <meta content="Castle Court, Whitechurch" property="twitter:title"/>
        <meta content="Register your interest in a new home in Castle Court, Whitechurch" property="twitter:description"/>
        <meta property="og:type" content="website"/>
        <meta content="summary_large_image" name="twitter:card"/>

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>
        <meta name="author" content="Sussd Mortgages"/>
    </>
}