import React from 'react';


function Blurb(props) {

    return (
        <div>
            <h3>The very best of everything</h3>
            <p>Castle Court is a new development of exclusive family homes,
                located in the beautiful setting of Whitechurch village. With the
                rolling hills of Cork countryside all around and the city just 11kms
                away to the south, it’s the perfect place to enjoy relaxed rural
                living in a vibrant community, with the attractions of a large urban
                area on your doorstep.</p>
            <p>
                Castle Court has 88 houses in all, offering a choice of 3 or 4
                bedroom homes, in a terrace, semi-detached or detached.
                Architecturally designed and professionally landscaped
                throughout, these homes have a premium look and feel, enhanced
                by the attention to detail in the quality interiors. Built using
                passive principles, these A2 rated dwellings offer reduced energy
                consumption making them extremely energy and cost efficient.</p>
            <p> All these features combined make Castle Court the perfect place
                to call home.</p>

        </div>
    );
}

export default Blurb;
