import React, {useEffect, useState} from 'react';
import axios from "axios";
//import secureLocalStorage from "react-secure-storage";
export const RatesContext = React.createContext({})
export function calcPMT(rate, nperiod, pv, fv, type) {
    if (!fv) fv = 0;
    if (!type) type = 0;

    if (rate === 0) return -(pv + fv) / nperiod;

    var pvif = Math.pow(1 + rate, nperiod);
    var pmt = rate / (pvif - 1) * -(pv * pvif + fv);

    if (type === 1) {
        pmt /= (1 + rate);
    }
    return pmt;
}
export function calcMonthlyPayment(required, term, rate) {
    let trueRate = (parseFloat(rate) / 100) / 12;
    const pmt = calcPMT(trueRate, term * 12, required) * -1;
    return Math.round(pmt);
}
export function calcTotalPayment(term, monthly) {
    return term * 12 * monthly
}
export function ltv(loan, value) {
    // to 1 dec place
    return Math.round((loan / value) * 100 * 10) / 10
}
export function saveAnswer(key, value) {
  //   let answers = secureLocalStorage.getItem('answers')
  //   if (!answers) {
  //       answers = {}
  //   }
  //   answers[key] = value
  //
  // //  console.log(answers)
  //   secureLocalStorage.setItem('answers', answers);
}
export function allAnswers(){
    let answers = false //secureLocalStorage.getItem('answers')
    if (!answers) {
        answers = {}
    }
    return answers
}
export function getAnswer(key) {
    // let answers = secureLocalStorage.getItem('answers')
    // if (!answers) {
    //     answers = {}
    // }
    // if (answers.hasOwnProperty(key)){
    //     return answers[key]
    // }
    return null
    // answers[key]=value
    // cookies.set('answers', JSON.stringify(answers), { path: '/' });
    // console.log(cookies.get('answers'))
}
function RatesProvider(props) {
    const [rates, setRates] = useState(false)
    useEffect(() => {
        //console.log('rates provider useEffect')
        if (!rates) {
            //console.log('fetching latest rates')
            const endpoint = 'https://itdhvdcdl4ozbvsic34gs6kxzu0okfdm.lambda-url.eu-west-1.on.aws/'
            axios.get(endpoint).then(response => {
                setRates(response.data)
            })
        }
    })
    const approvedRates = (propertyValue, loanRequired, loanTerm) => {
        if (!rates) {
            return []
        }
        const myLTV = ltv(loanRequired, propertyValue)
        let lastError = false
        const r = rates.filter(item => {
            if (!!item?.buyToLet){
                return false
            }
            if (myLTV > item.ltv) {
                lastError = 'Try decreasing the loan amount'
                return false
            }
            if (item?.minLoan && item.minLoan > loanRequired) {
                lastError = 'Try increasing the loan amount'
                return false
            }
            if (item?.maxLoan && item.maxLoan < loanRequired) {
                lastError = 'Try reducing the loan amount'
                return false
            }
            if (item?.minTerm && item.minTerm > loanTerm) {
                lastError = 'Try increasing the loan term'
                return false
            }
            if (item?.maxTerm && item.maxTerm < loanTerm) {
                lastError = 'Try decreasing the loan term'
                return false
            }
            return true
        })
        if (!r.length > 0) return {error: lastError}
        // remove ltv matches that are higher than the lowest ltv match

        const objectified = {}
        r.forEach(item=>{
            if (!objectified.hasOwnProperty(item.lender)){objectified[item.lender]= {}}
            if (!objectified[item.lender].hasOwnProperty(item.fixed)){ objectified[item.lender][item.fixed]=[] }
            objectified[item.lender][item.fixed].push(item)
        })

        // filter out any results whose ltv is higher than the lowest unless the rate it better  in all lender/term combinations
        let revised = []
        Object.keys(objectified).forEach(lender=>{
            Object.keys(objectified[lender]).forEach(term=>{
                //get the rate of lowest ltv and exclude any rates not as good from the same lender
                let group =  [...objectified[lender][term]]

                let lowestRatesInGroup = []
                let lowestRate = objectified[lender][term].reduce((prev,curr)=>{
                    return prev.rate < curr.rate ? prev : curr
                })
                lowestRatesInGroup.push(lowestRate)
                if (lowestRate?.conditions){
                    group = group.filter(rate=>{
                        return rate.id !== lowestRate.id
                    })
                    if (group.length){
                        let nextLowestRate = group.reduce((prev,curr)=>{
                            return prev.rate < curr.rate ? prev : curr
                        })
                        lowestRatesInGroup.push(nextLowestRate)
                    }

                }

                // let lowestLTV = objectified[lender][term].reduce((prev,curr)=>{
                //     return prev.ltv < curr.ltv ? prev : curr
                // })
                // let lowerRates =  objectified[lender][term].filter(rate=>{
                //     return rate.rate < lowestLTV.rate
                // })
                revised = [...revised, ...lowestRatesInGroup]
            })
        })

        return revised


        //return r.length > 0 ? r : {error: lastError}
    }
    return (
        <RatesContext.Provider value={{
            rates: rates,
            getApprovedRates: approvedRates,
            calcPMT: calcPMT,
            calcMonthlyPayment: calcMonthlyPayment,
            calcTotalPayment: calcTotalPayment,
            ltv: ltv,
            answer: {
                save: saveAnswer,
                get: getAnswer,
                all: allAnswers
            }
        }}>
            {props.children}
        </RatesContext.Provider>
    );
}

export default RatesProvider;